import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from 'sostereo-services';
import { CommonService } from '../angular/shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    @Inject('environment') private environment,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.data && route.data.externalUrl) {
      window.location.href = route.data.externalUrl;
    }
    const loggedUser = this.authenticationService.getCurrentUser();
    const scopes = route.data.scopes as Array<string>;
    const subdomain = this.commonService.getSubdomain();
    const magicToken = route.queryParams.magicToken;
    const queryParams: any = !loggedUser
      ? { queryParams: { returnUrl: state.url.split('?')[0] } }
      : { queryParams: {} };
    if (magicToken) queryParams.queryParams.magicToken = magicToken;

    if (!loggedUser) {
      let routeL = '/login';
      this.router.navigate([routeL], queryParams);
      return false;
    } else if (scopes && !this.commonService.isAllowed(scopes)) {
      let routeD = '/discover';
      this.router.navigate([routeD]);
      return false;
    }
    // TODO check user permissions to load a specific view
    if (this.environment.name === 'sostereo') {
      const companies = loggedUser.groups.filter((group) => !group.includes('resources'));
      if (!subdomain || companies.includes(subdomain)) {
        // User is at the right subdomain and has permissions
        return true;
      } else {
        // Redirect user to right subdomain. ex: brand.sostereo.com to login
        this.authenticationService.logout(true);
        this.router.navigate(['/login'], { queryParams });
        return false;
      }
    }
    return true;
  }
}
